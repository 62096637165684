import { useSelector } from "react-redux";
import {
  Paper,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Zoom,
  Typography,
} from "@material-ui/core";
import { BondTableData, BondDataCard } from "./BondRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { trim } from "../../helpers";
import useBonds from "../../hooks/Bonds";
import "./choosebond.scss";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";

function ChooseBond() {
  const { bonds } = useBonds();
  const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query
  const isVerySmallScreen = useMediaQuery("(max-width: 420px)");

  const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
  const marketPrice = useSelector<IReduxState, number>(state => {
    return state.app.marketPrice;
  });

  const treasuryBalance = useSelector<IReduxState, number>(state => {
    return state.app.treasuryBalance;
  });

  return (
    <div id="choose-bond-view">
      <Zoom in={true}>
        <Paper className="rgk-card">
          <Box className="card-header">
            <Typography variant="h5" data-testid="t">
              Bond (1,1)
            </Typography>
          </Box>

          <Grid container item xs={12} style={{ margin: "10px 0px 20px", padding: "2rem 0" }} className="bond-hero">
            <Grid item xs={1} />
            <Grid item xs={4}>
              <Box
                textAlign={`${isVerySmallScreen ? "left" : "center"}`}
                style={{ border: "1px solid #c73636", padding: "3rem 1rem", borderRadius: "15px" }}
              >
                <Typography variant="h5" color="textSecondary">
                  Treasury Balance
                </Typography>
                <Box>
                  <Typography variant="h4" data-testid="treasury-balance">
                    {isAppLoading ? (
                      <Skeleton width="180px" />
                    ) : (
                      new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      }).format(treasuryBalance)
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={2} />

            <Grid item xs={4} className={`rgk-price`}>
              <Box
                textAlign={`${isVerySmallScreen ? "right" : "center"}`}
                style={{ border: "1px solid #c73636", padding: "3rem 1rem", borderRadius: "15px" }}
              >
                <Typography variant="h5" color="textSecondary">
                  RGK Price
                </Typography>
                <Typography variant="h4">
                  {isAppLoading ? <Skeleton width="100px" /> : `$${trim(marketPrice, 2)}`}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {!isSmallScreen && (
            <Grid container item>
              <TableContainer>
                <Table aria-label="Available bonds">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Bond</TableCell>
                      <TableCell align="left">Price</TableCell>
                      <TableCell align="left">ROI</TableCell>
                      <TableCell align="right">Purchased</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bonds
                      .filter(bond => !bond.name.includes("old"))
                      .map(bond => {
                        return <BondTableData key={bond.name} bond={bond} />;
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}

          {!isSmallScreen && (
            <Grid container item>
              <TableContainer>
                <Table aria-label="Available bonds">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Bond</TableCell>
                      <TableCell align="left">Price</TableCell>
                      <TableCell align="left">ROI</TableCell>
                      <TableCell align="right">Purchased</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bonds
                      .filter(bond => bond.name.includes("old"))
                      .map(bond => {
                        return <BondTableData key={bond.name} bond={bond} />;
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Paper>
      </Zoom>

      {isSmallScreen && (
        <Box className="rgk-card-container">
          <Grid container item spacing={2}>
            {bonds
              .filter(bond => !bond.name.includes("old"))
              .map(bond => {
                return (
                  <Grid item xs={12} key={bond.name}>
                    <BondDataCard key={bond.name} bond={bond} />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      )}

      {isSmallScreen && (
        <Box className="rgk-card-container">
          <Grid container item spacing={2}>
            {bonds
              .filter(bond => bond.name.includes("old"))
              .map(bond => {
                return (
                  <Grid item xs={12} key={bond.name}>
                    <BondDataCard key={bond.name} bond={bond} />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      )}
    </div>
  );
}

export default ChooseBond;
