import { useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { Typography, Box } from "@material-ui/core";
import { trim, formatCurrency } from "../../../helpers";
import InfoTooltip from "src/uikit/InfoTooltip/InfoTooltip.jsx";

export const Metric = props => <Box className={`metric ${props.className}`}>{props.children}</Box>;

export const convert = n => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`;
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`;
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`;
  if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`;
};

Metric.Value = props => <Typography variant="h5">{props.children || <Skeleton type="text" />}</Typography>;

Metric.Title = props => (
  <Typography variant="h6" color="textSecondary">
    {props.children}
  </Typography>
);

Metric.BigValue = props => <Typography variant="h2">{props.children || <Skeleton type="text" />}</Typography>;

Metric.BigTitle = props => (
  <Typography variant="h3" color="textSecondary">
    {props.children}
  </Typography>
);

export const MarketCap = ({ isAppLoading, app }) => {
  return (
    <Metric className="market">
      <Metric.Title>Market Cap</Metric.Title>
      <Metric.Value>
        {isAppLoading ? (
          <Skeleton width="160px" />
        ) : (
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(app.marketCap)
        )}
      </Metric.Value>
    </Metric>
  );
};

export const OHMPrice = ({ isAppLoading, app }) => {
  return (
    <Metric className="price">
      <Metric.BigTitle>RGK Price</Metric.BigTitle>
      <Metric.BigValue>{isAppLoading ? <Skeleton width="100px" /> : `$${trim(app.marketPrice, 2)}`}</Metric.BigValue>
    </Metric>
  );
};

export const CircSupply = ({ isAppLoading, app }) => {
  return (
    <Metric className="circ">
      <Metric.Title>Supply (Staked/Total)</Metric.Title>
      <Metric.Value>
        {" "}
        {isAppLoading ? (
          <Skeleton width="250px" />
        ) : (
          `${new Intl.NumberFormat("en-US", {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(app.circSupply)}
                                        /
                                        ${new Intl.NumberFormat("en-US", {
                                          maximumFractionDigits: 0,
                                          minimumFractionDigits: 0,
                                        }).format(app.totalSupply)}`
        )}
      </Metric.Value>
    </Metric>
  );
};

export const BackingPerOHM = ({ isAppLoading, app }) => {
  return (
    <Metric className="bpo">
      <Metric.Title>Backing per $RGK</Metric.Title>
      <Metric.Value>
        {isAppLoading ? (
          <Skeleton width="250px" />
        ) : (
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(app.rfv)
        )}
      </Metric.Value>
    </Metric>
  );
};

export const CurrentIndex = ({ isAppLoading, app }) => {
  return (
    <Metric className="index">
      <Metric.Title>
        Current Index
        <InfoTooltip message="The current index tracks the amount of RGK accumulated since the beginning of staking. Basically, how much RGK one would have if they staked and held a single RGK from day 1." />
      </Metric.Title>
      <Metric.Value>
        {isAppLoading ? <Skeleton width="250px" /> : `${trim(Number(app.currentIndex), 2)} RGK`}
      </Metric.Value>
    </Metric>
  );
};

export const Treasury = ({ isAppLoading, app }) => {
  return (
    <Metric className="bpo">
      <Metric.Title>Treasury Balance</Metric.Title>
      <Metric.Value>
        {isAppLoading ? (
          <Skeleton width="250px" />
        ) : (
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(0)
        )}
      </Metric.Value>
    </Metric>
  );
};

export const Tvl = ({ isAppLoading, app }) => {
  return (
    <Metric className="index">
      <Metric.Title>TVL</Metric.Title>
      <Metric.Value>
        {isAppLoading ? (
          <Skeleton width="250px" />
        ) : (
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(app.stakingTVL)
        )}
      </Metric.Value>
    </Metric>
  );
};

export const Runaway = ({ isAppLoading, app }) => {
  return (
    <Metric className="index">
      <Metric.Title>Runway</Metric.Title>
      <Metric.Value>
        {isAppLoading ? <Skeleton width="250px" /> : `${trim(Number(app.runway) || 0, 1)} Days`}
      </Metric.Value>
    </Metric>
  );
};

export const Apy = ({ isAppLoading, app }) => {
  const trimmedStakingAPY = trim(app.stakingAPY * 100, 1);

  return (
    <Metric className="index">
      <Metric.Title>Apy</Metric.Title>
      <Metric.Value>
        {isAppLoading && <Skeleton width="250px" />}

        {!isAppLoading && `${convert(Number(trimmedStakingAPY))}%`}

        {!isAppLoading && (
          <small style={{ fontSize: "0.6em" }}>
            <br />
            {new Intl.NumberFormat("en-US").format(Number(trimmedStakingAPY))}%
          </small>
        )}
      </Metric.Value>
    </Metric>
  );
};
