import React, { useState, useEffect, forwardRef, useCallback } from "react";
import classnames from "classnames";
import { LinearProgress, Snackbar, makeStyles } from "@material-ui/core";
import { useSnackbar, SnackbarContent } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Message } from "../../store/slices/messages-slice";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import { Color } from "@material-ui/lab/Alert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: "10px",
  },
});

function Linear({ close }: { close: any }) {
  const [progress, setProgress] = useState(100);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 0) {
          clearInterval(timer);
          close();
          return 0;
        }
        const diff = oldProgress - 5;
        return diff;
      });
    }, 333);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.root}>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
}

const SnackMessage = forwardRef<HTMLDivElement, { id: string | number; message: Message }>((props, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded(oldExpanded => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  const getIcon = (severity: Color) => {
    switch (severity) {
      case "error":
        return <ErrorIcon color="inherit" />;
      case "info":
        return <InfoIcon color="inherit" />;
      case "success":
        return <SuccessIcon color="inherit" />;
      case "warning":
        return <WarningIcon color="inherit" />;
      default:
        return <div />;
    }
  };

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Alert
        variant="filled"
        icon={false}
        severity={props.message.severity}
        onClose={handleDismiss}
        // NOTE (appleseed): mui includes overflow-wrap: "break-word", but word-break: "break-word" is needed for webKit browsers
        style={{ wordBreak: "break-word" }}
      >
        {props.message.text}
        <Linear close={handleDismiss} />
      </Alert>
    </SnackbarContent>
  );
});

export default SnackMessage;
