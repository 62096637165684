import { useSelector } from "react-redux";
import { Paper, Grid, Box, Zoom, Container, useMediaQuery, SvgIcon } from "@material-ui/core";
import { trim } from "../../helpers";
import "./dashboard.scss";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";
import { IAppSlice } from "../../store/slices/app-slice";
import ReactTooltip from "react-tooltip";
import {
  MarketCap,
  OHMPrice,
  CircSupply,
  BackingPerOHM,
  CurrentIndex,
  Treasury,
  Tvl,
  Runaway,
  Apy,
} from "./metrics/metric";

function Dashboard() {
  const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
  const app = useSelector<IReduxState, IAppSlice>(state => state.app);

  const isSmallScreen = useMediaQuery("(max-width: 650px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 379px)");

  return (
    <div id="treasury-dashboard-view" className={`${isSmallScreen && "smaller"} ${isVerySmallScreen && "very-small"}`}>
      <Container
        style={{
          paddingLeft: isSmallScreen || isVerySmallScreen ? "0" : "3.3rem",
          paddingRight: isSmallScreen || isVerySmallScreen ? "0" : "3.3rem",
        }}
      >
        <Box className="hero-metrics">
          <Paper className="rgk-card">
            <Box display="flex" flexWrap="wrap" justifyContent="space-around" alignItems="center">
              <MarketCap isAppLoading={isAppLoading} app={app} />
              <Treasury isAppLoading={isAppLoading} app={app} />
              <Tvl isAppLoading={isAppLoading} app={app} />
              <BackingPerOHM isAppLoading={isAppLoading} app={app} />
              <OHMPrice isAppLoading={isAppLoading} app={app} />
              <CircSupply isAppLoading={isAppLoading} app={app} />
              <CurrentIndex isAppLoading={isAppLoading} app={app} />
              <Apy isAppLoading={isAppLoading} app={app} />
            </Box>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}

export default Dashboard;
