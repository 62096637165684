import { JsonRpcProvider, Provider, StaticJsonRpcProvider } from "@ethersproject/providers";
import axios from "axios";
import { ethers } from "ethers";
import { abi as MemoContract } from "../abi/tokens/SRugContract.json";
import { getMainnetURI } from "src/hooks/web3/helpers";

const cache: { [key: string]: number } = {};

export const loadTokenPrices = async () => {
  const url = "https://api.coingecko.com/api/v3/simple/price?ids=magic-internet-money,usd-coin&vs_currencies=usd";
  const { data } = await axios.get(url);

  cache["MIM"] = 1;
  cache["USDC"] = 1;
};

export const getTokenPrice = (symbol: string): number => {
  return Number(cache[symbol]);
};
