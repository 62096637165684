import ReactDOM from "react-dom";
import store from "./store/store";
import { Provider } from "react-redux";
import { Web3ContextProvider } from "./hooks";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import SnackMessage from "./components/Messages/snackbar";

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider
      maxSnack={4}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      content={(key, message: string) => <SnackMessage id={key} message={JSON.parse(message)} />}
      autoHideDuration={10000}
    >
      <Web3ContextProvider>
        <BrowserRouter basename={"/#"}>
          <App />
        </BrowserRouter>
      </Web3ContextProvider>
    </SnackbarProvider>
  </Provider>,
  document.getElementById("root"),
);
