import { Backdrop, Container, SvgIcon } from "@material-ui/core";
import "./loading.scss";

function LoadingSplash() {
  return (
    <Backdrop open={true} className="loading-splash" style={{ zIndex: 33, backdropFilter: "blur(33px)" }}>
      <Container justify="center" align="center">
        <img
          src="/logo.png"
          className="loading-icon pulse"
          style={{ fontSize: "5.1875rem", height: "150px", width: "150px" }}
        />
      </Container>
    </Backdrop>
  );
}

export default LoadingSplash;
