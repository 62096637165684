import { ChangeEvent, useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { trim } from "../../helpers";
import { Grid, Backdrop, Paper, Box, Tab, Tabs, Fade, useMediaQuery, Typography } from "@material-ui/core";
import TabPanel from "../../components/TabPanel";
import BondHeader from "./BondHeader";
import BondRedeem from "./BondRedeem";
import BondPurchase from "./BondPurchase";
import "./bond.scss";
import { useWeb3Context } from "../../hooks";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";
import { IAllBondData } from "../../hooks/Bonds";

interface IBondProps {
  bond: IAllBondData;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Bond({ bond }: IBondProps) {
  const { provider, address } = useWeb3Context();

  const [slippage, setSlippage] = useState(0.5);
  const [recipientAddress, setRecipientAddress] = useState(address);
  const [view, setView] = useState(bond.name.includes("old") || bond.name.includes("usdc") ? 1 : 0);

  const isVerySmallScreen = useMediaQuery("(max-width: 420px)");

  const isBondLoading = useSelector<IReduxState, boolean>(state => state.bonding.loading ?? true);

  const onRecipientAddressChange = (value: any) => {
    return setRecipientAddress(value);
  };

  const onSlippageChange = (value: any) => {
    return setSlippage(value);
  };

  useEffect(() => {
    if (address) setRecipientAddress(address);
  }, [provider, address]);

  const changeView = (event: ChangeEvent<{}>, value: string | number): void => {
    setView(Number(value));
  };

  return (
    <Fade in={true} mountOnEnter unmountOnExit>
      <Grid className="bond-view">
        <Backdrop open={true} className="bond-view" style={{ zIndex: "10" }}>
          <Fade in={true}>
            <Paper className="rgk-card rgk-modal">
              <BondHeader
                bond={bond}
                slippage={slippage}
                recipientAddress={recipientAddress}
                onSlippageChange={onSlippageChange}
                onRecipientAddressChange={onRecipientAddressChange}
              />
              <Grid container item xs={12} style={{ margin: "10px 0px 20px", padding: "2rem 0" }} className="bond-hero">
                <Grid item xs={1} />
                <Grid item xs={4}>
                  <Box
                    className="bond-price-data"
                    style={{ border: "1px solid #c73636", padding: "3rem 1rem", borderRadius: "15px" }}
                    textAlign={`${isVerySmallScreen ? "left" : "center"}`}
                  >
                    <Typography variant="h5" color="textSecondary">
                      Bond Price
                    </Typography>
                    <Typography variant="h3" className="price" color="primary">
                      {isBondLoading ? <Skeleton width="50px" /> : <DisplayBondPrice key={bond.name} bond={bond} />}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4}>
                  <Box
                    className="bond-price-data"
                    style={{
                      border: "1px solid #c73636",
                      padding: "3rem 1rem",
                      borderRadius: "15px",
                    }}
                    textAlign={`${isVerySmallScreen ? "left" : "center"}`}
                  >
                    <Typography variant="h5" color="textSecondary">
                      Market Price
                    </Typography>
                    <Typography variant="h3" color="primary" className="price">
                      {isBondLoading ? <Skeleton /> : `$${trim(bond.marketPrice, 2)}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Tabs
                centered
                value={view}
                textColor="primary"
                indicatorColor="primary"
                onChange={changeView}
                aria-label="bond tabs"
              >
                <Tab
                  aria-label="bond-tab-button"
                  label="Bond"
                  {...a11yProps(0)}
                  disabled={bond.name.includes("old") || bond.name.includes("usdc")}
                />
                <Tab aria-label="redeem-tab-button" label="Redeem" {...a11yProps(1)} />
              </Tabs>

              <TabPanel value={view} index={0}>
                <BondPurchase bond={bond} slippage={slippage} recipientAddress={recipientAddress} />
              </TabPanel>

              <TabPanel value={view} index={1}>
                <BondRedeem bond={bond} />
              </TabPanel>
            </Paper>
          </Fade>
        </Backdrop>
      </Grid>
    </Fade>
  );
}

export const DisplayBondPrice = ({ bond }: { bond: IAllBondData }) => {
  if (typeof bond.bondPrice === undefined) {
    return <Fragment>--</Fragment>;
  }

  return (
    <Fragment>
      {new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(bond.bondPrice)}
    </Fragment>
  );
};

export const DisplayBondDiscount = ({ bond }: { bond: IAllBondData }) => {
  if (typeof bond.bondDiscount === undefined) {
    return <Fragment>--</Fragment>;
  }

  return <Fragment>{bond.bondDiscount && trim(bond.bondDiscount * 100, 2)}%</Fragment>;
};

export default Bond;
