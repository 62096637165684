import { Networks } from "../../constants/blockchain";
import { LPBond, CustomLPBond } from "./lp-bond";
import { StableBond, CustomBond } from "./stable-bond";
import USDCIcon from "../../assets/tokens/USDC.e.png";
import MimIcon from "../../assets/tokens/MIM.svg";
import MimRugIcon from "../../assets/tokens/RUG-MIM.svg";
import USDTEIcon from "../../assets/tokens/USDT.e.png";

import {
  StableBondContract,
  LpBondContract,
  WavaxBondContract,
  StableReserveContract,
  LpReserveContract,
} from "../../abi";

export const oldMim = new StableBond({
  name: "old-mim",
  displayName: "MIM (old)",
  bondToken: "MIM",
  bondIconSvg: MimIcon,
  bondContractABI: StableBondContract,
  reserveContractAbi: StableReserveContract,
  networkAddrs: {
    [Networks.AVAX]: {
      bondAddress: "0x8c42Fe3c8DF7E0e2d41e1FAAa75511c22F17aF0f",
      reserveAddress: "0x130966628846BFd36ff31a822705796e8cb8C18D",
    },
  },
});

export const mim = new StableBond({
  name: "mim",
  displayName: "MIM",
  bondToken: "MIM",
  bondIconSvg: MimIcon,
  bondContractABI: StableBondContract,
  reserveContractAbi: StableReserveContract,
  networkAddrs: {
    [Networks.AVAX]: {
      bondAddress: "0x4E6Bfc87322974C2Ac04a66A29a212ae5cEcA451",
      reserveAddress: "0x130966628846BFd36ff31a822705796e8cb8C18D",
    },
  },
});

export const mimRgkOld = new LPBond({
  name: "mim-rgk-lp-old",
  displayName: "MIM-RGK LP (old)",
  bondToken: "MIM",
  bondIconImg: "/rgk_mim.png",
  bondContractABI: LpBondContract,
  reserveContractAbi: LpReserveContract,
  networkAddrs: {
    [Networks.AVAX]: {
      bondAddress: "0x0f11DEbabD1131970E1E93d07eB4427FAA5D5691",
      reserveAddress: "0xe8419ecda1c76c38800b21e7d43bdb6b02f51ace",
    },
  },
  lpUrl:
    "https://www.traderjoexyz.com/#/pool/0x130966628846BFd36ff31a822705796e8cb8C18D/0x46fCCf447f74ec97a521615fcE111118597071f7",
});

export const mimRgk = new LPBond({
  name: "mim-rgk-lp",
  displayName: "MIM-RGK LP",
  bondToken: "MIM",
  bondIconImg: "/rgk_mim.png",
  bondContractABI: LpBondContract,
  reserveContractAbi: LpReserveContract,
  networkAddrs: {
    [Networks.AVAX]: {
      bondAddress: "0xc26f1b62f59CD066b4Fb6c52D387792EA8F35926",
      reserveAddress: "0xe8419ecda1c76c38800b21e7d43bdb6b02f51ace",
    },
  },
  lpUrl:
    "https://www.traderjoexyz.com/#/pool/0x130966628846BFd36ff31a822705796e8cb8C18D/0x46fCCf447f74ec97a521615fcE111118597071f7",
});

export const usdc = new StableBond({
  name: "usdc",
  displayName: "USDC.e",
  bondToken: "USDC",
  bondIconSvg: USDCIcon,
  bondContractABI: StableBondContract,
  reserveContractAbi: StableReserveContract,
  networkAddrs: {
    [Networks.AVAX]: {
      bondAddress: "0x72b87ae6566CFD27DeeAAcAa48B79B50eE050D93",
      reserveAddress: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
    },
  },
});

export const usdt = new StableBond({
  name: "usdt",
  displayName: "USDT.e",
  bondToken: "USDT",
  bondIconSvg: USDTEIcon,
  bondContractABI: StableBondContract,
  reserveContractAbi: StableReserveContract,
  networkAddrs: {
    [Networks.AVAX]: {
      bondAddress: "0x9D83bfC91B665095E30e5422Cfb15e810c120E1C",
      reserveAddress: "0xc7198437980c041c805a1edcba50c1ce5db95118",
    },
  },
});

export default [mim, usdc, usdt, mimRgk, oldMim, mimRgkOld];
