import MimIcon from "../assets/tokens/MIM.svg";
import RugIcon from "../assets/tokens/RUG.svg";
import UsdcEIcon from "../assets/tokens/USDC.e.png";

export interface IToken {
  name: string;
  address: string;
  img: string;
  isAvax?: boolean;
  decimals: number;
}

export const mim: IToken = {
  name: "MIM",
  address: "0x130966628846BFd36ff31a822705796e8cb8C18D",
  img: MimIcon,
  decimals: 18,
};

const rgk: IToken = {
  name: "RGK",
  address: "0x46fCCf447f74ec97a521615fcE111118597071f7",
  img: RugIcon,
  decimals: 9,
};

const usdc: IToken = {
  name: "USDC.e",
  address: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
  img: UsdcEIcon,
  decimals: 6,
};

export default [mim, rgk, usdc];
