import { useSelector, useDispatch } from "react-redux";
import { Box, Slide, Button, Typography } from "@material-ui/core";
import { IBondDetails, redeemBond } from "../../store/slices/bond-slice";
import { useWeb3Context } from "../../hooks";
import { trim, prettifySeconds, prettyVestingPeriod } from "../../helpers";
import { IPendingTxn, isPendingTxn, txnButtonText } from "../../store/slices/pending-txns-slice";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";
import { IAllBondData } from "../../hooks/Bonds";
import { IUserBondDetails } from "../../store/slices/account-slice";
import { messages } from "../../constants/messages";
import { warning } from "../../store/slices/messages-slice";
import ConnectButton from "../../uikit/ConnectButton";
import { DisplayBondDiscount } from "./index";

interface IBondRedeem {
  bond: IAllBondData;
}

function BondRedeem({ bond }: IBondRedeem) {
  const dispatch = useDispatch();
  const { provider, address, chainID, checkWrongNetwork } = useWeb3Context();

  const isBondLoading = useSelector<IReduxState, boolean>(state => state.bonding.loading ?? true);

  const currentBlockTime = useSelector<IReduxState, number>(state => {
    return state.app.currentBlockTime;
  });

  const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
    return state.pendingTransactions;
  });

  const bondingState = useSelector<IReduxState, IBondDetails>(state => {
    return state.bonding && state.bonding[bond.name];
  });

  const bondDetails = useSelector<IReduxState, IUserBondDetails>(state => {
    return state.account.bonds && state.account.bonds[bond.name];
  });

  async function onRedeem(autostake: boolean) {
    if (await checkWrongNetwork()) return;

    if (bond.interestDue === 0 || bond.pendingPayout === 0) {
      dispatch(warning({ text: messages.nothing_to_claim }));
      return;
    }

    await dispatch(redeemBond({ address, bond, networkID: chainID, provider, autostake }));
  }

  const vestingTime = () => {
    if (!bondDetails) {
      return "";
    }
    return prettyVestingPeriod(currentBlockTime, bondDetails.bondMaturationBlock);
  };

  const vestingPeriod = () => {
    return prettifySeconds(bondingState.vestingTerm, "day");
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-around" flexWrap="wrap">
        {!address ? (
          <ConnectButton />
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              id="bond-claim-btn"
              className="transaction-button"
              fullWidth
              onClick={() => {
                if (isPendingTxn(pendingTransactions, "redeem_bond_" + bond.name)) return;
                onRedeem(false);
              }}
            >
              {txnButtonText(pendingTransactions, "redeem_bond_" + bond.name, "Claim")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              id="bond-claim-autostake-btn"
              className="transaction-button"
              fullWidth
              onClick={() => {
                if (isPendingTxn(pendingTransactions, "redeem_bond_" + bond.name + "_autostake")) return;
                onRedeem(true);
              }}
            >
              {txnButtonText(pendingTransactions, "redeem_bond_" + bond.name + "_autostake", "Claim and Autostake")}
            </Button>
          </>
        )}
      </Box>
      <Slide direction="right" in={true} mountOnEnter unmountOnExit {...{ timeout: 533 }}>
        <Box className="bond-data">
          <div className="data-row">
            <Typography>Pending Rewards</Typography>
            <Typography className="price-data">
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.interestDue, 4)} RGK`}
            </Typography>
          </div>
          <div className="data-row">
            <Typography>Claimable Rewards</Typography>
            <Typography id="claimable" className="price-data">
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.pendingPayout, 4)} RGK`}
            </Typography>
          </div>
          <div className="data-row">
            <Typography>Time until fully vested</Typography>
            <Typography className="price-data">{isBondLoading ? <Skeleton width="100px" /> : vestingTime()}</Typography>
          </div>

          <div className="data-row">
            <Typography>ROI</Typography>
            <Typography>
              {isBondLoading ? <Skeleton width="100px" /> : <DisplayBondDiscount key={bond.name} bond={bond} />}
            </Typography>
          </div>

          <div className="data-row">
            <Typography>Vesting Term</Typography>
            <Typography>{isBondLoading ? <Skeleton width="100px" /> : vestingPeriod()}</Typography>
          </div>
        </Box>
      </Slide>
    </Box>
  );
}

export default BondRedeem;
