import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState, useCallback } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useTheme from "./hooks/useTheme";
import useBonds from "./hooks/Bonds";
import { useAddress, useWeb3Context } from "./hooks/web3";
import { IReduxState } from "./store/slices/state.interface";
import { calcBondDetails } from "./store/slices/bond-slice";
import { loadAppDetails, IAppSlice } from "./store/slices/app-slice";
import { loadTokenPrices } from "./helpers";
import BG from "./assets/images/bg.svg";
import {
  loadAccountDetails,
  calculateUserBondDetails,
  calculateUserTokenDetails,
  loadWarmUpInfo,
} from "./store/slices/account-slice";
import useTokens from "./hooks/tokens";

import { Stake, ChooseBond, Bond, Dashboard, NotFound, Calculator } from "./views";
import TopBar from "./uikit/TopBar/TopBar.jsx";
import NavDrawer from "./uikit/Sidebar/NavDrawer.jsx";
import Loading from "./uikit/Loading/LoadingSplash";
import Messages from "./uikit/Messages/Messages";

import { dark as darkTheme } from "./themes/dark.js";
import "./style.scss";

const drawerWidth = 280;
const transitionDuration = 969;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: transitionDuration,
    }),
    height: "100%",
    overflow: "auto",
    marginLeft: 0,
    background: `url(${BG}) no-repeat`,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: transitionDuration,
    }),
    marginLeft: 0,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
}));

function App() {
  const dispatch = useDispatch();
  const [theme, toggleTheme, mounted] = useTheme();
  const location = useLocation();
  const currentPath = location.pathname + location.search + location.hash;
  const classes = useStyles();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmallerScreen = useMediaQuery("(max-width: 980px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const { connect, hasCachedProvider, provider, chainID, connected } = useWeb3Context();
  const address = useAddress();
  const { tokens } = useTokens();

  const [walletChecked, setWalletChecked] = useState(false);

  const [isAppLoading, setAppLoading] = useState(true);
  const app = useSelector<IReduxState, IAppSlice>(state => state.app);

  const { bonds } = useBonds();
  async function loadDetails(whichDetails: string) {
    let loadProvider = provider;

    if (whichDetails === "app") {
      loadApp(loadProvider);
    }

    if (whichDetails === "account" && address && connected) {
      loadAccount(loadProvider);
      if (app.loading) return;

      loadApp(loadProvider);
    }

    if (whichDetails === "userBonds" && address && connected) {
      bonds.map(bond => {
        dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
      });
    }

    if (whichDetails === "userTokens" && address && connected) {
      tokens.map(token => {
        dispatch(calculateUserTokenDetails({ address, token, provider, networkID: chainID }));
      });
    }
  }

  const loadApp = useCallback(
    loadProvider => {
      dispatch(loadAppDetails({ networkID: chainID, provider: loadProvider }));
      dispatch(loadWarmUpInfo({ networkID: chainID, address, provider: loadProvider }));
      bonds.map(bond => {
        dispatch(calcBondDetails({ bond, value: null, provider: loadProvider, networkID: chainID }));
      });
      tokens.map(token => {
        dispatch(calculateUserTokenDetails({ address: "", token, provider, networkID: chainID }));
      });
    },
    [connected],
  );

  const loadAccount = useCallback(
    loadProvider => {
      dispatch(loadAccountDetails({ networkID: chainID, address, provider: loadProvider }));
    },
    [connected],
  );

  useEffect(() => {
    loadTokenPrices().then(() => setAppLoading(false));
  }, []);

  useEffect(() => {
    if (hasCachedProvider()) {
      connect().then(() => {
        setWalletChecked(true);
      });
    } else {
      setWalletChecked(true);
    }
  }, []);

  useEffect(() => {
    if (walletChecked) {
      loadDetails("app");
      loadDetails("account");
      loadDetails("userBonds");
      loadDetails("userTokens");
    }
  }, [walletChecked]);

  useEffect(() => {
    if (connected) {
      loadDetails("app");
      loadDetails("account");
      loadDetails("userBonds");
      loadDetails("userTokens");
    }
  }, [connected]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSidebarClose = () => {
    setIsSidebarExpanded(false);
  };

  useEffect(() => {
    if (isSidebarExpanded) handleSidebarClose();
  }, [location]);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Messages />
      {(isAppLoading || app.loading) && <Loading />}
      {!isAppLoading && !app.loading && (
        <div className={`app ${isSmallerScreen && "tablet"} ${isSmallScreen && "mobile"} ${theme}`}>
          <div style={{ boxShadow: !isSmallerScreen ? "15px 15px 100px -2px #000" : "none", marginBottom: "20px" }}>
            <TopBar theme={theme} toggleTheme={toggleTheme} handleDrawerToggle={handleDrawerToggle} />
          </div>
          {isSmallerScreen && (
            <nav className={classes.drawer}>
              <NavDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
            </nav>
          )}

          <div className={`${classes.content} ${isSmallerScreen && classes.contentShift}`}>
            <Switch>
              <Route exact path="/dashboard">
                <Dashboard />
              </Route>

              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>

              <Route path="/stake">
                <Stake />
              </Route>

              <Route path="/calculator">
                <Calculator />
              </Route>

              <Route path="/bonds">
                {bonds.map(bond => {
                  return (
                    <Route exact key={bond.name} path={`/bonds/${bond.name}`}>
                      <Bond bond={bond} />
                    </Route>
                  );
                })}
                <ChooseBond />
              </Route>

              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
}

export default App;
