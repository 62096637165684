import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  OutlinedInput,
  InputAdornment,
  Slide,
  FormControl,
  Typography,
  InputLabel,
  Button,
} from "@material-ui/core";
import { shorten, trim, prettifySeconds } from "../../helpers";
import { changeApproval, bondAsset, calcBondDetails } from "../../store/slices/bond-slice";
import { useWeb3Context } from "../../hooks";
import { IPendingTxn, isPendingTxn, txnButtonText } from "../../store/slices/pending-txns-slice";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";
import { IAllBondData } from "../../hooks/Bonds";
import useDebounce from "../../hooks/Debounce";
import { messages } from "../../constants/messages";
import { warning } from "../../store/slices/messages-slice";
import ConnectButton from "../../uikit/ConnectButton";
import { DisplayBondDiscount } from "./index";

interface IBondPurchaseProps {
  bond: IAllBondData;
  slippage: number;
  recipientAddress: string;
}

function BondPurchase({ bond, slippage, recipientAddress }: IBondPurchaseProps) {
  const dispatch = useDispatch();
  const { provider, address, chainID, checkWrongNetwork } = useWeb3Context();

  const [quantity, setQuantity] = useState("");
  const [useAvax, setUseAvax] = useState(false);

  const isBondLoading = useSelector<IReduxState, boolean>(state => state.bonding.loading ?? true);

  const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
    return state.pendingTransactions;
  });

  const vestingPeriod = () => {
    return prettifySeconds(bond.vestingTerm, "day");
  };

  async function onBond() {
    console.log(await checkWrongNetwork());
    if (await checkWrongNetwork()) return;

    if (quantity === "") {
      dispatch(warning({ text: messages.before_minting }));
      //@ts-ignore
    } else if (isNaN(quantity)) {
      dispatch(warning({ text: messages.before_minting }));
    } else if (bond.interestDue > 0 || bond.pendingPayout > 0) {
      const shouldProceed = window.confirm(messages.existing_mint);
      if (shouldProceed) {
        const trimBalance = trim(Number(quantity), 10);

        await dispatch(
          bondAsset({
            value: trimBalance,
            slippage,
            bond,
            networkID: chainID,
            provider,
            address: recipientAddress || address,
            useAvax,
          }),
        );
        clearInput();
      }
    } else {
      const trimBalance = trim(Number(quantity), 10);
      await dispatch(
        //@ts-ignore
        bondAsset({
          value: trimBalance,
          slippage,
          bond,
          networkID: chainID,
          provider,
          address: recipientAddress || address,
          useAvax,
        }),
      );
      clearInput();
    }
  }

  const clearInput = () => {
    setQuantity("");
  };

  const hasAllowance = useCallback(() => {
    return bond.allowance > 0;
  }, [bond.allowance]);

  const setMax = () => {
    let amount: any = Math.min(bond.maxBondPriceToken * 0.9999, useAvax ? bond.avaxBalance * 0.99 : bond.balance);

    if (amount) {
      amount = trim(amount);
    }

    setQuantity((amount || "").toString());
  };

  const bondDetailsDebounce = useDebounce(quantity, 1000);

  useEffect(() => {
    dispatch(calcBondDetails({ bond, value: quantity, provider, networkID: chainID }));
  }, [bondDetailsDebounce]);

  const onSeekApproval = async () => {
    await dispatch(changeApproval({ address, bond, provider, networkID: chainID }));
    console.log("az");
  };

  const displayUnits = useAvax ? "AVAX" : bond.displayUnits;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-around" flexWrap="wrap">
        {!address ? (
          <ConnectButton />
        ) : (
          <>
            {!hasAllowance() ? (
              <div className="help-text">
                <em>
                  <Typography variant="body1" align="center" color="textSecondary">
                    First time bonding <b>{bond.displayName}</b>? <br />
                    Please approve Ragnarok Dao to use your <b>{bond.displayName}</b> for bonding
                  </Typography>
                </em>
              </div>
            ) : (
              <FormControl className="rgk-input" variant="outlined" color="primary" fullWidth>
                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  type="number"
                  value={quantity}
                  onChange={e => setQuantity(e.target.value)}
                  labelWidth={55}
                  endAdornment={
                    <InputAdornment position="end">
                      <Button variant="text" onClick={setMax}>
                        Max
                      </Button>
                    </InputAdornment>
                  }
                />
              </FormControl>
            )}
            {hasAllowance() ? (
              <Button
                variant="contained"
                color="primary"
                id="bond-btn"
                className="transaction-button"
                onClick={async () => {
                  if (isPendingTxn(pendingTransactions, "bond_" + bond.name)) return;
                  await onBond();
                }}
              >
                {txnButtonText(pendingTransactions, "bond_" + bond.name, "Bond")}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                id="bond-approve-btn"
                className="transaction-button"
                onClick={async () => {
                  console.log(isPendingTxn(pendingTransactions, "approve_" + bond.name));
                  if (isPendingTxn(pendingTransactions, "approve_" + bond.name)) return;
                  await onSeekApproval();
                }}
              >
                {txnButtonText(pendingTransactions, "approve_" + bond.name, "Approve")}
              </Button>
            )}
          </>
        )}{" "}
      </Box>

      <Slide direction="left" in={true} mountOnEnter unmountOnExit {...{ timeout: 533 }}>
        <Box className="bond-data">
          <div className="data-row">
            <Typography>Your Balance</Typography>{" "}
            <Typography id="bond-balance">
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.balance, 4)} ${displayUnits}`}
            </Typography>
          </div>

          <div className={`data-row`}>
            <Typography>You Will Get</Typography>
            <Typography id="bond-value-id" className="price-data">
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.bondQuote, 4) || "0"} RGK`}
            </Typography>
          </div>

          <div className={`data-row`}>
            <Typography>Max You Can Buy</Typography>
            <Typography id="bond-value-id" className="price-data">
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.maxBondPrice, 4) || "0"} RGK`}
            </Typography>
          </div>

          <div className="data-row">
            <Typography>ROI</Typography>
            <Typography>
              {isBondLoading ? <Skeleton width="100px" /> : <DisplayBondDiscount key={bond.name} bond={bond} />}
            </Typography>
          </div>

          <div className="data-row">
            <Typography>Vesting Term</Typography>
            <Typography>{isBondLoading ? <Skeleton width="100px" /> : vestingPeriod()}</Typography>
          </div>

          {recipientAddress !== address && (
            <div className="data-row">
              <Typography>Recipient</Typography>
              <Typography>{isBondLoading ? <Skeleton width="100px" /> : shorten(recipientAddress)}</Typography>
            </div>
          )}
        </Box>
      </Slide>
    </Box>
  );
}

export default BondPurchase;
