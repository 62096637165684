import "./notfound.scss";

function NotFound() {
  return (
    <div id="not-found">
      <div className="not-found-header">
        <a href="https://app.ragnarokdao.finance" target="_blank">
          <img className="branding-header-icon" src="/logo.png" alt="RagnarokDao" />
        </a>

        <h4>Page not found</h4>
      </div>
    </div>
  );
}

export default NotFound;
