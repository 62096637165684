import { AppBar, Link, Typography, Divider, Toolbar, Box, Button, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ReactComponent as MenuIcon } from "../../assets/icons/hamburger.svg";
import { ReactComponent as StakeIcon } from "../../assets/icons/stake.svg";
import { ReactComponent as BondIcon } from "../../assets/icons/bond.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as PresaleIcon } from "../../assets/icons/presale.svg";
import { ReactComponent as OlympusIcon } from "../../assets/icons/olympus-nav-header.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/home.svg";
import { ReactComponent as ZapIcon } from "../../assets/icons/info-fill.svg";
import OhmMenu from "./OhmMenu.jsx";
import ConnectMenu from "./ConnectMenu.jsx";
import "./topbar.scss";
import { trim, shorten } from "../../helpers";
import { NavLink } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { useAddress, useWeb3Context } from "src/hooks/web3";
import { useCallback, useState } from "react";

const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingLeft: "3.3rem",
      paddingRight: "3.3rem",
      maxWidth: "1280px",
    },
    background: "transparent",
    backdropFilter: "none",
    zIndex: 10,

    marginLeft: "auto",
    marginRight: "auto",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("981")]: {
      display: "none",
    },
  },
}));

function TopBar({ theme, toggleTheme, handleDrawerToggle }) {
  const [isActive] = useState();
  const classes = useStyles();
  const address = useAddress();
  const isVerySmallScreen = useMediaQuery("(max-width: 355px)");
  const isSmallerScreen = useMediaQuery("(max-width: 980px)");

  const checkPage = useCallback((match, location, page) => {
    const currentPath = location.pathname.replace("/", "");
    if (currentPath.indexOf("dashboard") >= 0 && page === "dashboard") {
      return true;
    }
    if (currentPath.indexOf("stake") >= 0 && page === "stake") {
      return true;
    }
    if (currentPath.indexOf("presale") >= 0 && page === "presale") {
      return true;
    }
    if (currentPath.indexOf("calculator") >= 0 && page === "calculator") {
      return true;
    }
    if ((currentPath.indexOf("bonds") >= 0 || currentPath.indexOf("choose_bond") >= 0) && page === "bonds") {
      return true;
    }
    return false;
  }, []);

  return (
    <AppBar position="sticky" className={classes.appBar} elevation={0}>
      <Toolbar disableGutters className="dapp-topbar">
        <Button
          id="hamburger"
          aria-label="open drawer"
          edge="start"
          size="large"
          variant="contained"
          color="secondary"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <SvgIcon component={MenuIcon} />
        </Button>

        {!isSmallerScreen && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Link href="https://ragnarokdao.finance" target="_blank">
              <SvgIcon
                color="primary"
                component={OlympusIcon}
                viewBox="0 0 313.163 110"
                style={{ height: "70px", width: "200px", marginRight: "10px" }}
              />
            </Link>
            <div className="dapp-nav-top">
              <Box display="flex" height="100%">
                <a href="https://ragnarokdao.finance" style={{ color: "white", textDecoration: "none" }}>
                  <Typography variant="h6">
                    <SvgIcon color="primary" component={HomeIcon} />
                    Home
                  </Typography>
                </a>
                <Link
                  component={NavLink}
                  id="dash-nav"
                  to="/dashboard"
                  isActive={(match, location) => {
                    return checkPage(match, location, "dashboard");
                  }}
                  className={`button-dapp-menu ${isActive ? "active" : ""}`}
                >
                  <Typography variant="h6">
                    <SvgIcon color="primary" component={DashboardIcon} />
                    Dashboard
                  </Typography>
                </Link>
                <Link
                  component={NavLink}
                  id="stake-nav"
                  to="/stake"
                  isActive={(match, location) => {
                    return checkPage(match, location, "stake");
                  }}
                  className={`button-dapp-menu ${isActive ? "active" : ""}`}
                >
                  <Typography variant="h6">
                    <SvgIcon color="primary" component={StakeIcon} />
                    Stake
                  </Typography>
                </Link>
                <Link
                  component={NavLink}
                  id="bond-nav"
                  to="/bonds"
                  isActive={(match, location) => {
                    return checkPage(match, location, "bonds");
                  }}
                  className={`button-dapp-menu ${isActive ? "active" : ""}`}
                >
                  <Typography variant="h6">
                    <SvgIcon color="primary" component={BondIcon} />
                    Bond
                  </Typography>
                </Link>
                <Link
                  component={NavLink}
                  id="dash-nav"
                  to="/calculator"
                  isActive={(match, location) => {
                    return checkPage(match, location, "calculator");
                  }}
                  className={`button-dapp-menu ${isActive ? "active" : ""}`}
                >
                  <Typography variant="h6">
                    <SvgIcon color="primary" component={ZapIcon} />
                    Calculator
                  </Typography>
                </Link>
              </Box>
            </div>
          </Box>
        )}

        <Box display="flex" justifyContent="center" alignItems="center">
          {address && (
            <div className="wallet-link">
              <Link href={`https://snowtrace.io/address/${address}`} target="_blank">
                {shorten(address)}
              </Link>
            </div>
          )}
          {!isVerySmallScreen && <OhmMenu />}

          {/* <NetworkMenu /> */}
          <ConnectMenu theme={theme} />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
