import { Networks } from "./blockchain";

const AVAX_MAINNET = {
  RGK_ADDRESS: "0x46fCCf447f74ec97a521615fcE111118597071f7",
  SRGK_ADDRESS: "0xeff46B56Fb421bC0B6438DC4AE84d945573C0D6D",
  MIM_ADDRESS: "0x130966628846bfd36ff31a822705796e8cb8c18d",
  TREASURY_ADDRESS: "0xfa9cCe3098C02F770DcB2D17Df9B8f5331bE86D0",
  STAKING_ADDRESS: "0xeb6B44834E002ECDEAdF9E61448B21cCc33284ca",
  STAKING_HELPER_ADDRESS: "0x995bF0e7Ab61dBAf382FD6484c4Cae80b9A55Dcc",
  USDC_ADDRESS: "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
  DAI_ADDRESS: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
  RUG_BONDING_CALC_ADDRESS: "0xfaA70Fe165ddB29B4F4e335a2E3251c7b396F4E9",

  ZAPIN_ADDRESS: "0xaB2D3F4a9C4E929D7ffF1DFce7BAF0F9532048CA",
  BURN_ADDRESS: "0x000000000000000000000000000000000000dead",
  ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
};

export const getAddresses = (networkID: number) => {
  if (networkID === Networks.AVAX) return AVAX_MAINNET;

  throw Error("Network don't support");
};

export const addresses = AVAX_MAINNET;
