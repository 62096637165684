import {
  Box,
  Modal,
  Paper,
  SvgIcon,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import "./bondSettings.scss";

interface IAdvancedSettingsProps {
  open: boolean;
  handleClose: () => void;
  slippage: number;
  recipientAddress: string;
  onRecipientAddressChange: (e: any) => void;
  onSlippageChange: (e: any) => void;
}

function AdvancedSettings({
  open,
  handleClose,
  slippage,
  recipientAddress,
  onRecipientAddressChange,
  onSlippageChange,
}: IAdvancedSettingsProps) {
  const [value, setValue] = useState(slippage);

  useEffect(() => {
    let timeount: any = null;
    clearTimeout(timeount);

    timeount = setTimeout(() => onSlippageChange(value), 1000);
    return () => clearTimeout(timeount);
  }, [value]);

  return (
    <Modal id="hades" open={open} onClose={handleClose} hideBackdrop>
      <Paper className="rgk-card rgk-popover">
        <Box display="flex">
          <IconButton onClick={handleClose}>
            <SvgIcon color="primary" component={XIcon} />
          </IconButton>
          <Typography variant="h3">Hades</Typography>
        </Box>

        <Box className="card-content">
          <InputLabel htmlFor="slippage">Slippage</InputLabel>
          <FormControl variant="outlined" color="primary" fullWidth>
            <OutlinedInput
              id="slippage"
              value={value}
              onChange={(e: any) => setValue(e.target.value)}
              fullWidth
              type="number"
              className="bond-input"
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
            />
            <div className="help-text">
              <Typography variant="body2" color="textSecondary">
                Transaction may revert if price changes by more than slippage %
              </Typography>
            </div>
          </FormControl>

          <InputLabel htmlFor="recipient">Recipient Address</InputLabel>
          <FormControl variant="outlined" color="primary" fullWidth>
            <OutlinedInput id="recipient" value={recipientAddress} onChange={onRecipientAddressChange} type="text" />
            <div className="help-text">
              <Typography variant="body2" color="textSecondary">
                Choose recipient address. By default, this is your currently connected address
              </Typography>
            </div>
          </FormControl>
        </Box>
      </Paper>
    </Modal>
  );
}

export default AdvancedSettings;
