import { useState, useCallback, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  InputAdornment,
  OutlinedInput,
  Zoom,
  Paper,
  Typography,
  Button,
  Box,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import RebaseTimer from "../../components/RebaseTimer";
import TabPanel from "../../uikit/TabPanel";
import { trim } from "../../helpers";
import { changeStake, changeApproval } from "../../store/slices/stake-thunk";
import "./stake.scss";
import { useWeb3Context } from "../../hooks";
import { IPendingTxn, isPendingTxn, txnButtonText } from "../../store/slices/pending-txns-slice";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";
import { messages } from "../../constants/messages";
import classnames from "classnames";
import { warning } from "../../store/slices/messages-slice";
import { MouseEvent } from "react";
import { Popper, Fade } from "@material-ui/core";
import { forfeitOrClaim } from "../../store/slices/warmup-thunk";
import { sleep } from "../../helpers";
import WarmUpTimer from "src/components/WarmUpTimer";
import BasicModal from "../../components/Modal";
import StakeRow from "./StakeRow";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const convert = (n: number) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`;
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`;
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`;
  if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`;
};

function Stake() {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();

  const [zoomed, setZoomed] = useState(false);
  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState<string>("");

  const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
  const currentIndex = useSelector<IReduxState, string>(state => {
    return state.app.currentIndex;
  });
  const fiveDayRate = useSelector<IReduxState, number>(state => {
    return state.app.fiveDayRate;
  });
  const timeBalance = useSelector<IReduxState, string>(state => {
    return state.account.balances && state.account.balances.rgk;
  });
  const warmupBalance = useSelector<IReduxState, string>(state => {
    return state.account.warmupInfo && state.account.warmupInfo.deposit;
  });
  const gonsBalance = useSelector<IReduxState, string>(state => {
    return state.account.warmupInfo && state.account.warmupInfo.gonsBalance;
  });
  const warmupExpiry = useSelector<IReduxState, string>(state => {
    return state.account.warmupInfo && state.account.warmupInfo.expiry;
  });
  const currentEpoch = useSelector<IReduxState, string>(state => {
    return state.account.warmupInfo && state.account.warmupInfo.epoch;
  });
  const memoBalance = useSelector<IReduxState, string>(state => {
    return state.account.balances && state.account.balances.srgk;
  });
  const stakeAllowance = useSelector<IReduxState, number>(state => {
    return state.account.staking && state.account.staking.rgk;
  });
  const unstakeAllowance = useSelector<IReduxState, number>(state => {
    return state.account.staking && state.account.staking.srgk;
  });
  const stakingRebase = useSelector<IReduxState, number>(state => {
    return state.app.stakingRebase;
  });
  const stakingAPY = useSelector<IReduxState, number>(state => {
    return state.app.stakingAPY;
  });
  const stakingTVL = useSelector<IReduxState, number>(state => {
    return state.app.stakingTVL;
  });

  const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
    return state.pendingTransactions;
  });

  const setMax = () => {
    if (view === 0) {
      const fullBalance = Number(timeBalance);
      setQuantity(trim(fullBalance, 4));
    } else {
      setQuantity(memoBalance);
    }
  };

  const onSeekApproval = async (token: string) => {
    if (await checkWrongNetwork()) return;

    await dispatch(changeApproval({ address, token, provider, networkID: chainID }));
  };

  const onChangeStake = async (action: string) => {
    if (await checkWrongNetwork()) return;
    if (quantity === "" || parseFloat(quantity) === 0) {
      dispatch(warning({ text: action === "stake" ? messages.before_stake : messages.before_unstake }));
    } else {
      await dispatch(
        changeStake({
          address,
          action,
          value: String(quantity),
          provider,
          networkID: chainID,
          warmUpBalance: Number(warmupBalance),
        }),
      );
      setQuantity("");
    }
  };

  const onChangeWarmup = async (action: string) => {
    if (await checkWrongNetwork()) return;
    await dispatch(
      forfeitOrClaim({
        address,
        action,
        provider,
        networkID: chainID,
      }),
    );
  };

  const hasAllowance = useCallback(
    token => {
      if (token === "rgk") return stakeAllowance > 0;
      if (token === "srgk") return unstakeAllowance > 0;
      return 0;
    },
    [stakeAllowance],
  );

  const changeView = (_event: React.ChangeEvent<{}>, newView: number) => {
    setView(newView);
    setQuantity("");
  };

  const trimmedMemoBalance = trim(Number(memoBalance), 6);
  const trimmedStakingAPY = trim(stakingAPY * 100, 1);
  const stakingRebasePercentage = trim(stakingRebase * 100, 4);
  const nextRewardValue = trim((Number(stakingRebasePercentage) / 100) * Number(trimmedMemoBalance), 6);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  let modalButton = [];

  modalButton.push(
    <Button variant="contained" color="primary" className="connect-button" onClick={connect} key={1}>
      Connect Wallet
    </Button>,
  );

  const open = Boolean(anchorEl);

  return (
    <div id="stake-view">
      <Zoom in={true} onEntered={() => setZoomed(true)}>
        <Paper className={`rgk-card`}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <div className="card-header">
                <Typography variant="h5">Single Stake (3, 3)</Typography>
                <RebaseTimer />
              </div>
            </Grid>

            <Grid item>
              <div className="stake-top-metrics">
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div
                      className="stake-apy"
                      style={{ border: "1px solid #c73636", padding: "3rem 1rem", borderRadius: "15px" }}
                    >
                      <Typography variant="h5" color="textSecondary">
                        APY
                      </Typography>
                      <Typography variant="h4">
                        {stakingAPY ? (
                          <span data-testid="apy-value">
                            {stakingAPY && `${convert(Number(trimmedStakingAPY))}%`}

                            {stakingAPY && (
                              <small style={{ fontSize: "0.6em" }}>
                                <br />
                                {new Intl.NumberFormat("en-US").format(Number(trimmedStakingAPY))}%
                              </small>
                            )}
                          </span>
                        ) : (
                          <Skeleton width="150px" data-testid="apy-loading" />
                        )}
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div
                      className="stake-tvl"
                      style={{ border: "1px solid #c73636", padding: "3rem 1rem", borderRadius: "15px" }}
                    >
                      <Typography variant="h5" color="textSecondary">
                        Total Value Deposited
                      </Typography>
                      <Typography variant="h4">
                        {stakingTVL ? (
                          <span data-testid="tvl-value">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0,
                            }).format(stakingTVL)}
                          </span>
                        ) : (
                          <Skeleton width="150px" data-testid="tvl-loading" />
                        )}
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div
                      className="stake-index"
                      style={{ border: "1px solid #c73636", padding: "3rem 1rem", borderRadius: "15px" }}
                    >
                      <Typography variant="h5" color="textSecondary">
                        Current Index
                      </Typography>
                      <Typography variant="h4">
                        {currentIndex ? (
                          <span data-testid="index-value">{trim(Number(currentIndex), 1)} RGK</span>
                        ) : (
                          <Skeleton width="150px" data-testid="index-loading" />
                        )}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <div className="staking-area" style={{ paddingTop: "2rem" }}>
              {!address ? (
                <div className="stake-wallet-notification">
                  <div className="wallet-menu" id="wallet-menu">
                    {modalButton}
                  </div>
                  <Typography variant="h6">Connect your wallet to stake RGK</Typography>
                </div>
              ) : (
                <>
                  <Box className="stake-action-area">
                    <Tabs
                      key={String(zoomed)}
                      centered
                      value={view}
                      textColor="primary"
                      indicatorColor="primary"
                      className="stake-tab-buttons"
                      onChange={changeView}
                      aria-label="stake tabs"
                      //hides the tab underline sliding animation in while <Zoom> is loading
                      TabIndicatorProps={!zoomed ? { style: { display: "none" } } : undefined}
                    >
                      <Tab label="Stake" {...a11yProps(0)} />
                      <Tab label="Unstake" {...a11yProps(1)} />
                    </Tabs>
                    <Grid container className="stake-action-row">
                      <Grid item xs={12} sm={8} className="stake-grid-item">
                        {address &&
                          ((!hasAllowance("rgk") && view === 0) || (!hasAllowance("srgk") && view === 1) ? (
                            <Box className="help-text">
                              <Typography variant="body1" className="stake-note" color="textSecondary">
                                {view === 0 ? (
                                  <>
                                    First time staking <b>RGK</b>? <br />
                                    Please approve Ragnarok Dao to use your <b>RGK</b> for staking
                                  </>
                                ) : (
                                  <>
                                    First time unstaking <b>sRGK</b>? Please approve Ragnarok Dao to use your{" "}
                                    <b>sRGK</b> for unstaking.
                                  </>
                                )}
                              </Typography>
                            </Box>
                          ) : (
                            <FormControl className="rgk-input" variant="outlined" color="primary">
                              <InputLabel htmlFor="amount-input"></InputLabel>
                              <OutlinedInput
                                id="amount-input"
                                type="number"
                                placeholder="Enter an amount"
                                className="stake-input"
                                value={quantity}
                                onChange={e => setQuantity(e.target.value)}
                                labelWidth={0}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Button variant="text" onClick={setMax} color="inherit">
                                      Max
                                    </Button>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          ))}
                      </Grid>
                      <Grid item xs={12} sm={4} className="stake-grid-item">
                        <TabPanel value={view} index={0} className="stake-tab-panel">
                          <Box m={-2}>
                            {address && hasAllowance("rgk") ? (
                              <Button
                                className="stake-button"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  if (isPendingTxn(pendingTransactions, "staking")) return;
                                  onChangeStake("stake");
                                }}
                              >
                                {txnButtonText(pendingTransactions, "staking", "Stake RGK")}
                              </Button>
                            ) : (
                              <Button
                                className="stake-button"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  if (isPendingTxn(pendingTransactions, "approve_staking")) return;
                                  onSeekApproval("rgk");
                                }}
                              >
                                {txnButtonText(pendingTransactions, "approve_staking", "Approve")}
                              </Button>
                            )}
                          </Box>
                        </TabPanel>

                        <TabPanel value={view} index={1} className="stake-tab-panel">
                          <Box m={-2}>
                            {address && hasAllowance("srgk") ? (
                              <Button
                                className="stake-button"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  if (isPendingTxn(pendingTransactions, "unstaking")) return;
                                  onChangeStake("unstake");
                                }}
                              >
                                {txnButtonText(pendingTransactions, "unstaking", "Unstake RGK")}
                              </Button>
                            ) : (
                              <Button
                                className="stake-button"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  if (isPendingTxn(pendingTransactions, "approve_unstaking")) return;
                                  onSeekApproval("srgk");
                                }}
                              >
                                {txnButtonText(pendingTransactions, "approve_unstaking", "Approve")}
                              </Button>
                            )}
                          </Box>
                        </TabPanel>
                      </Grid>
                    </Grid>
                  </Box>

                  <div className="stake-user-data">
                    <StakeRow
                      title="Your Balance"
                      id="user-balance"
                      balance={`${trim(Number(timeBalance), 4)} RGK`}
                      {...{ isAppLoading }}
                    />

                    <br />
                    <StakeRow
                      title="Your Warm Up Balance"
                      id="warmup-balance"
                      balance={`${trim(Number(warmupBalance), 4)} sRGK`}
                      {...{ isAppLoading }}
                    />

                    {Number(warmupBalance) < Number(gonsBalance) && (
                      <>
                        <StakeRow
                          title="Warm Up Balance with Rebase Rewards"
                          id="rebease-rewards"
                          balance={`${trim(Number(gonsBalance), 4)} sRGK`}
                          {...{ isAppLoading }}
                        />
                      </>
                    )}

                    <div className="data-row">
                      <p className="data-row-name">Pending Warm Up Till Release</p>
                      <p className="data-row-value">
                        {isAppLoading ? (
                          <Skeleton width="80px" />
                        ) : Number(warmupExpiry) <= Number(currentEpoch) ? (
                          <>
                            <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                if (isPendingTxn(pendingTransactions, "claim")) return;
                                onChangeWarmup("claim");
                              }}
                            >
                              {txnButtonText(pendingTransactions, "claim", "Claim sRGK")}
                            </Button>
                          </>
                        ) : (
                          <div className="warmup-text">
                            {" "}
                            {Number(warmupExpiry) - Number(currentEpoch)} Rebase(s) left till claimable
                            <div className="forfeit-btn">{BasicModal(onChangeWarmup)}</div>
                          </div>
                        )}
                      </p>
                    </div>

                    <StakeRow
                      title="Your Staked Balance"
                      id="stacked-balance"
                      balance={`${trim(Number(trimmedMemoBalance), 4)} sRGK`}
                      {...{ isAppLoading }}
                    />
                    <StakeRow title="Next Reward Amount" balance={`${nextRewardValue} sRGK`} {...{ isAppLoading }} />
                    <StakeRow title="Next Reward Yield" balance={`${stakingRebasePercentage}%`} {...{ isAppLoading }} />
                    <StakeRow
                      title="ROI (5-Day Rate)"
                      balance={`${trim(Number(fiveDayRate) * 100, 4)}%`}
                      {...{ isAppLoading }}
                    />
                  </div>
                </>
              )}
            </div>
          </Grid>
        </Paper>
      </Zoom>
    </div>
  );
}

export default Stake;
